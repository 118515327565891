import './Headline.css';

interface HeadlineProps {
  headline: string;
  url: string;
  isMain?: boolean;
}

function Headline({ headline, url, isMain = false }: HeadlineProps) {
  return (
    <div className={isMain ? 'main-headline headline' : 'headline'}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {headline}
      </a>
    </div>
  );
}

export default Headline;
